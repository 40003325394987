<template>
  <div style="padding-bottom: 100px">
    <top-fixed></top-fixed>
    <div class="body_box">
      <div style="text-align: center; font-size: 18px; font-weight: 700">
        {{ helpData.title }}
      </div>
      <div class="body_mian">
        <div
          class="swiper-container notic_box"
          style="
            overflow: auto;
            height: 100%;
            margin-top: 50px;
            padding: 2rem 1rem;
          "
        >
          <img
            style="width: 80px; float: right; top: -14px; position: relative"
            src="../assets/img2/T.png"
          />
          <div class="swiper-wrapper" style="width: 100%">
            <img style="width: 100%" :src="helpData.imgurl" />
          </div>
        </div>
      </div>

      <div
        v-html="helpData.content"
        style="margin-left: 1rem; font-size: 0.6rem"
      ></div>
    </div>
  </div>
</template>

<script>
import TopFixed from "../components/TopFixed.vue";
export default {
  data () {
    return {
      helpData: '',
      show: false,
      showData: ''
    }
  },
  components: { TopFixed },
  created () {
    this.helpData = JSON.parse(decodeURIComponent(this.$route.query.item))
  },
  methods: {

  }
}
</script>

<style  scoped lang="scss">
.body_box {
  background: #fff;
  position: relative;
  top: -5px;
  border-radius: 10px;
  padding: 20px;
}
.body_mian {
  padding: 10px 20px 10px 20px;
  margin-top: 20px;
  border-radius: 10px;
}
.subtitle :first-child {
  color: rgb(191, 191, 191);
}
.helpNotic {
  /deep/ p {
    background: #f4f4f4;
    border: 0.5px solid #dddddd;
    border-radius: 9px;
    box-shadow: 0px 1.5px 3px 0px rgba(0, 0, 0, 0.08) inset;
    margin: 0.6rem;
    padding: 0.6rem 2%;
    width: 95%;
  }
}
.notic_box {
  background: url("../assets/img2/Account_bg.png");
  background-size: 100% 100%;
}
</style>